import { CH, DE, EU } from "country-flag-icons/react/3x2";

export interface FlagIconProps {
  underlying: string;
  height: number;
}

export default function FlagIcon(props: FlagIconProps) {
  if (!props.underlying) return null;
  switch (props.underlying) {
    case "Euro Stoxx 50":
    case "Euro Stoxx Banks":
    case "Stoxx Europe 600":
    case "Bobl":
    case "Schatz":
    case "BTP":
    case "OAT":
    case "Short BTP":
    case "Buxl":
      return <EU height={props.height} />;
    case "Bund":
      return <DE height={props.height} />;
    case "SMI":
      return <CH height={props.height} />;
    default:
      return null;
  }
}

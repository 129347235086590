import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { numericFormatter } from "react-number-format";
import { ParentOrder } from "../parentApiSlice";

export interface MoreDetailsProps {
  parent: ParentOrder;
}

const DETAILS_FONT_SIZE = "10px";

export default function MoreDetails(props: MoreDetailsProps) {
  const remaining = (props.parent["parent-order-aggregation"]?.["remaining-total"] || 0).toString();

  const filled = (
    props.parent["parent-order-aggregation"]?.["child-orders-quantity-by-state"]?.BROKER_FILLED || 0
  ).toString();

  const working = (
    props.parent["parent-order-aggregation"]?.["child-orders-quantity-by-state"]?.WORKING || 0
  ).toString();

  return (
    <Stack>
      <Typography variant="caption" mb={0.2}>
        More Details
      </Typography>
      <Stack direction="row">
        <Typography variant="body1" fontWeight={600} fontSize={DETAILS_FONT_SIZE} mr={0.5}>
          Remaining:
        </Typography>
        <Typography variant="numeric_body1" fontWeight={600} fontSize={DETAILS_FONT_SIZE}>
          {numericFormatter(remaining, { thousandSeparator: "," })}
        </Typography>
      </Stack>
      <Stack direction="row">
        <Typography variant="body1" fontSize={DETAILS_FONT_SIZE} mr={0.5}>
          {"Filled:"}
        </Typography>
        <Typography variant="numeric_body1" fontSize={DETAILS_FONT_SIZE}>
          {numericFormatter(filled, { thousandSeparator: "," })}
        </Typography>
      </Stack>
      <Stack direction="row">
        <Typography variant="body1" fontSize={DETAILS_FONT_SIZE} mr={0.5}>
          {"Working:"}
        </Typography>
        <Typography variant="numeric_body1" fontSize={DETAILS_FONT_SIZE}>
          {numericFormatter(working, { thousandSeparator: "," })}
        </Typography>
      </Stack>
      <Stack direction="row">
        <Typography variant="body1" fontSize={DETAILS_FONT_SIZE} mr={0.5}>
          {"Avg. Price:"}
        </Typography>
        <Typography variant="numeric_body1" fontSize={DETAILS_FONT_SIZE}>
          {props.parent["parent-order-aggregation"]?.["avg-price"] || "-"}
        </Typography>
      </Stack>
    </Stack>
  );
}

import { baseApi } from "@/config/BaseQuery";
import { OrderState } from "../child/childApiSlice";

export enum Direction {
  BUY = "BUY",
  SELL = "SELL",
}

export interface ParentOrderResponse {
  "parent-id": string;
  "timestamp": string;
}

export interface ParentOrder {
  "parent-id": string;
  "direction": Direction | "";
  "underlying": string;
  "term": string;
  "quantity": number;
  "active": boolean; // If false, this parent won't be returned in the list call.
  /**
   * If created in response to an RFQ, this will be the RFQ sender's name.
   */
  "rfq-client-name"?: string;
  "parent-order-aggregation"?: ParentOrderAggregation;
}

export interface ParentOrderAggregation {
  "child-orders-quantity-by-state": {
    [OrderState.Working]?: number;
    [OrderState.AwaitingMatch]?: number;
    [OrderState.Matched]?: number;
    [OrderState.PartialBrokerAccepted]?: number;
    [OrderState.BrokerAccepted]?: number;
    [OrderState.PartialBrokerFilled]?: number;
    [OrderState.BrokerFilled]?: number;
    [OrderState.Error]?: number;
    [OrderState.Cancelled]?: number;
    [OrderState.Rejected]?: number;
    [OrderState.Expired]?: number;
  };
  "remaining-total"?: number;
  "avg-price"?: number;
}

const parentApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getParentOrders: build.query<ParentOrderResponse[], void>({
      query: () => "/orders/parent/all-ids-timestamps",
      providesTags: [{ type: "ParentOrders" }],
    }),
    getParentOrder: build.query<ParentOrder, string>({
      query: (id: string) => `/orders/parent/${id}`,
      providesTags: [{ type: "SelectedParentOrder" }],
    }),
    createParentOrder: build.mutation<ParentOrder, Partial<ParentOrder>>({
      query: (body) => ({
        url: "/orders/parent",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ParentOrders", "SelectedParentOrder"],
    }),
    deleteParentOrder: build.mutation<ParentOrder, string>({
      query: (id) => ({
        url: `/orders/parent/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ParentOrders", "SelectedParentOrder"],
    }),
  }),
});

export const {
  useGetParentOrdersQuery,
  useGetParentOrderQuery,
  useCreateParentOrderMutation,
  useDeleteParentOrderMutation,
} = parentApiSlice;

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { OrderState } from "../../child/childApiSlice";
import { ParentOrderAggregation } from "../parentApiSlice";
import StatusBar from "./StatusBar";

export interface StatusBarGroupProps {
  aggregation: ParentOrderAggregation;
}

interface OrderStateTotal {
  state: OrderState;
  total: number;
}

const stateFilter = [OrderState.Cancelled, OrderState.Error, OrderState.Expired, OrderState.Rejected];

/**
 * Shows the parent order's child-state aggregation as a bar chart.
 */
export default function StatusBarGroup(props: StatusBarGroupProps) {
  const states: OrderStateTotal[] = (Object.keys(props.aggregation["child-orders-quantity-by-state"]) as OrderState[])
    .filter((key) => !stateFilter.includes(key))
    .map((key) => {
      return {
        state: key,
        total: props.aggregation["child-orders-quantity-by-state"][key] || 0,
      };
    });

  const total = (props.aggregation["remaining-total"] || 0) + states.reduce((agg, state) => agg + state.total, 0);

  return (
    <Stack>
      <Typography variant="caption" mb={0.5}>
        Status
      </Typography>
      <Stack direction="row" spacing={0.5} mr={2} height={20} borderRadius={1}>
        {states.map((status) => {
          return (
            <StatusBar key={status.state} status={status.state} percentageOfTotal={(status.total / total) * 100} />
          );
        })}
        <StatusBar status={null} percentageOfTotal={((props.aggregation["remaining-total"] || 0) / total) * 100} />
      </Stack>
    </Stack>
  );
}

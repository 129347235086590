import { startCase } from "lodash";
import { themeColors } from "@/app/theme";
import { OrderState } from "@/features/orders/child/childApiSlice";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

export interface StatusBarProps {
  status: OrderState | null;
  percentageOfTotal: number;
}

export default function StatusBar(props: StatusBarProps) {
  return (
    <Tooltip title={`${props.percentageOfTotal.toFixed(1)}% ` + (props.status ? startCase(props.status) : "Pending")}>
      <Box
        sx={{
          backgroundColor: props.status ? themeColors.state[props.status] : themeColors.white.secondary,
          color: themeColors.text.primary,
          width: `${props.percentageOfTotal}%`,
          borderRadius: 1,
        }}
      />
    </Tooltip>
  );
}

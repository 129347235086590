import ErrorCard from "@/components/ErrorCard";
import ScrollBox from "@/components/ScrollBox";
import PriceCard from "@/features/prices/components/PriceCard";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { range, round, uniq } from "lodash";
import { useEffect, useState } from "react";
import { InflightOfferStats, Offer, OfferType } from "../offers/offersApiSlice";
import { ChildOrder, OrderState } from "../orders/child/childApiSlice";
import { selectParent } from "../orders/parent/parentSlice";
import {
  selectElectronicPrices,
  selectPrice,
  setIsEmpty,
  setPrice,
  setElectronicPrices as setStoreElectronicPrices,
} from "./pricesSlice";
import usePriceQueries from "./usePriceQueries";

export interface Price {
  value: number;
  isElectronic: boolean;
}

interface PriceInfo {
  prices: Price[];
  floor: number;
  ceiling: number;
}

/**
 * @returns An array of prices based on market offers, underlying tick size,
 * and any working orders.
 */
const getPrices = (
  stats: InflightOfferStats | undefined,
  offers: Offer[] | undefined,
  childOrders: ChildOrder[] | undefined,
  selectedPrice: number | null
): PriceInfo => {
  // If we get a bad response from the API, return an empty object.
  if (stats === undefined || offers === undefined || childOrders === undefined)
    return { prices: [], floor: 0, ceiling: 0 };

  const offerPrices = offers.map((offer) => offer.price);
  // Working orders are not included in the offers API response, so we need to
  // check to see if we have any (and what price they were created at).
  const workingOrderPrices =
    childOrders?.filter((order) => order.state === OrderState.Working)?.map((order) => order.price) || [];

  // Similarly, any child order that has progressed to "matched" or beyond will
  // not be returned in the offers response, but they still provide important
  // context for prices in the market.
  // --------------------------------- Removed at James G's request.
  // const matureChildPrices =
  //   childOrders
  //     ?.filter((order) => matureStates.includes(order.state))
  //     ?.map((order) => order.price) || [];

  const intickPrices: number[] = [];
  const electronicTickSize = round(stats.tick * 10, 3);

  const priceSources = [
    ...offerPrices,
    ...workingOrderPrices,
    // ...matureChildPrices,
  ];
  const includeLimitPriceAsMinSource =
    priceSources.length && selectedPrice !== null
      ? Math.abs(Math.min(...priceSources) - selectedPrice) < electronicTickSize
      : false;
  const min = priceSources.length
    ? Math.min(...priceSources, ...(includeLimitPriceAsMinSource && selectedPrice !== null ? [selectedPrice] : []))
    : selectedPrice || 0;

  const includeLimitPriceAsMaxSource =
    priceSources.length && selectedPrice !== null
      ? Math.abs(Math.min(...priceSources) - selectedPrice) < electronicTickSize
      : false;
  const max = priceSources.length
    ? Math.max(...priceSources, ...(includeLimitPriceAsMaxSource && selectedPrice !== null ? [selectedPrice] : []))
    : selectedPrice || 0;

  // E.g. for 0.025, precision === 3 (decimal places).
  const precision = Math.ceil(Math.abs(Math.log10(stats.tick)));

  const ceiling = round(getElectronicPrice("ceiling", max, stats.tick), precision);
  const floor = round(getElectronicPrice("floor", min, stats.tick), precision);
  const end = round(floor - stats.tick, precision);

  // A list of all the prices that should be included (but might not be exactly
  // an integer multiple of the InTick tick size).
  const importantPrices = uniq([min, max, ...workingOrderPrices, ...offerPrices]);

  if (selectedPrice !== null && !importantPrices.includes(selectedPrice)) {
    importantPrices.push(selectedPrice);
  }

  // Iterate from max -> min.
  for (let i = ceiling; round(i, precision) > end; i -= stats.tick) {
    // InTick intervals are added here (there should be 9 in total).
    // Need to account for JS float precision errors, hence lots of rounding.
    intickPrices.push(round(i, 3));
  }

  // Combine the InTick prices and the important prices, with no duplicates.
  const prices: Price[] = uniq([...importantPrices, ...intickPrices])
    .map((p) => {
      return {
        value: p,
        isElectronic: p === ceiling || p === floor,
      };
    })
    .sort((a, b) => b.value - a.value); // Return prices in descending order.

  return { prices, floor, ceiling };
};

/**
 * @returns The nearest electronic price above or below a given price.
 */
const getElectronicPrice = (type: "floor" | "ceiling", startPrice: number, tickSize: number) => {
  const electronicTickSize = round(tickSize * 10, 3);
  const divisor = electronicTickSize * 1000;
  const step = electronicTickSize * 100;
  // These multiplication factors we ensure that we are not dealing with any
  // decimals, and therefore won't run into any float division errors.
  let current = startPrice * 1000;
  let stepCount = 0;
  const maxStepCount = 10;

  if (type === "floor") {
    while (current % step !== 0) {
      // Round the current price down to the nearest InTick tick size.
      current -= 1;
    }
    while (current % divisor !== 0) {
      stepCount++;
      if (stepCount > maxStepCount) throw new Error("Max step count exceeded.");
      current -= step;
    }
    return current / 1000;
  } else {
    while (current % step !== 0) {
      // Round the current price up to the nearest InTick tick size.
      current += 1;
    }
    while (current % divisor !== 0) {
      stepCount++;
      if (stepCount > maxStepCount) throw new Error("Max step count exceeded.");
      current += step;
    }
    return current / 1000;
  }
};

export const Prices = () => {
  const selectedParent = useAppSelector(selectParent);
  const selectedPrice = useAppSelector(selectPrice);
  const dispatch = useAppDispatch();

  const [activePrice, setActivePrice] = useState<number | null>(null);

  const {
    inflightOffers,
    inflightOffersError,
    isErrorOffersIds,
    isLoadingOfferIds,
    isSuccessOfferIds,
    refetchOfferIds,
    offerData,
    offersError,
    isErrorOffers,
    isLoadingOffers,
    isSuccessOffers,
    refetchOffers,
    inflightChildrenError,
    isErrorInflightChildren,
    isSuccessInflightChildren,
    refetchInflightChildren,
    childOrdersData,
    childOrdersError,
    isErrorChildOrders,
    isSuccessChildOrders,
    refetchChildOrders,
    getBestPrice,
  } = usePriceQueries();

  // Inform the application if there are no inflight offers.
  useEffect(() => {
    dispatch(setIsEmpty(!!!inflightOffers?.inflight?.length));
  }, [inflightOffers]);

  // Generate prices based on either the offers + instrument stats,
  // working orders, or a manually set limit price.
  const [prices, setPrices] = useState<Price[]>([]);
  const selectedElectronicPrices = useAppSelector(selectElectronicPrices);
  const [electronicPrices, setElectronicPrices] = useState(selectedElectronicPrices);
  useEffect(() => {
    // Timeout is used to debounce the function. User can freely enter a limit
    // price via the SummaryBox, and we need to ensure that the function does
    // not execute between keystrokes.
    const generatePrices = setTimeout(() => {
      const priceInfo = getPrices(inflightOffers?.stats, offerData, childOrdersData, selectedPrice);
      setPrices(priceInfo.prices);
      setElectronicPrices({
        floor: priceInfo.floor,
        ceiling: priceInfo.ceiling,
      });

      if (selectedPrice !== activePrice) {
        // Ensures the price cards know the current active price.
        setActivePrice(selectedPrice);
      }
    }, 100);

    return () => clearTimeout(generatePrices);
  }, [inflightOffers, childOrdersData, selectedPrice, offerData]);

  useEffect(() => {
    dispatch(setStoreElectronicPrices(electronicPrices));
  }, [electronicPrices]);

  const onClickPrice = (value: number) => {
    const newPrice = activePrice === value ? null : value;
    dispatch(setPrice(newPrice)); // Inform the SummaryBox.
    setActivePrice(newPrice); // Inform PriceCard & OfferCard components.
  };

  if (isErrorOffersIds) {
    return <ErrorCard error={inflightOffersError} refreshFn={refetchOfferIds} />;
  }

  if (isErrorOffers) {
    return <ErrorCard error={offersError} refreshFn={refetchOffers} />;
  }

  if (isErrorInflightChildren) {
    return <ErrorCard error={inflightChildrenError} refreshFn={refetchInflightChildren} />;
  }

  if (isErrorChildOrders) {
    return <ErrorCard error={childOrdersError} refreshFn={refetchChildOrders} />;
  }

  if (isLoadingOfferIds || isLoadingOffers) {
    return (
      <Stack spacing={2}>
        {range(5).map((i) => (
          <Skeleton key={i} height={120} variant="rounded" width="100%">
            <PriceCard
              price={{ value: 0, isElectronic: false }}
              offers={[]}
              isBest={false}
              onClick={onClickPrice}
              selectedPrice={activePrice}
            />
          </Skeleton>
        ))}
      </Stack>
    );
  }

  if (isSuccessOfferIds && isSuccessOffers && prices.length) {
    // Find the best bid and ask (if they exist).
    const bestAsk = getBestPrice(OfferType.ASK, offerData || [], childOrdersData || []);

    const bestBid = getBestPrice(OfferType.BID, offerData || [], childOrdersData || []);

    return (
      <ScrollBox offset={!!selectedParent ? 4 : 12}>
        {prices.map((price, i) => {
          // Find all the offers at this price.
          const asksAtPrice =
            offerData?.filter((offer) => offer.price === price.value && offer["bid-ask"] === OfferType.ASK) || [];

          const bidsAtPrice =
            offerData?.filter((offer) => offer.price === price.value && offer["bid-ask"] === OfferType.BID) || [];

          // Include any working child orders.
          if (isSuccessInflightChildren && isSuccessChildOrders) {
            (
              childOrdersData?.filter((child) => child.price === price.value && child.state === OrderState.Working) ||
              []
            ).forEach((child) => {
              if (child["bid-ask"] === OfferType.ASK) {
                asksAtPrice.push(child as Offer);
              } else {
                bidsAtPrice.push(child as Offer);
              }
            });
          }

          // I.e. this price contains either the best ask or best bid.
          const isBestAsk = asksAtPrice.some((offer) => offer.price === bestAsk);
          const isBestBid = bidsAtPrice.some((offer) => offer.price === bestBid);

          return (
            <div key={i}>
              {!!asksAtPrice.length && (
                <PriceCard
                  price={price}
                  isFirst={i === 0}
                  isLast={i === prices.length - 1}
                  isBest={isBestAsk}
                  offers={asksAtPrice}
                  onClick={onClickPrice}
                  selectedPrice={activePrice}
                />
              )}
              {!!bidsAtPrice.length && (
                <PriceCard
                  data-testid={`price-card-${price}`}
                  price={price}
                  isFirst={i === 0}
                  isLast={i === prices.length - 1}
                  isBest={isBestBid}
                  offers={bidsAtPrice}
                  onClick={onClickPrice}
                  selectedPrice={activePrice}
                />
              )}
              {bidsAtPrice.length === 0 && asksAtPrice.length === 0 && (
                <PriceCard
                  price={price}
                  isFirst={i === 0}
                  isLast={i === prices.length - 1}
                  isBest={false}
                  offers={[]}
                  onClick={onClickPrice}
                  selectedPrice={activePrice}
                />
              )}
            </div>
          );
        })}
      </ScrollBox>
    );
  }

  return null;
};

import { useRef, useState } from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Direction, ParentOrder, useCreateParentOrderMutation } from "../parentApiSlice";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { NumericFormat } from "react-number-format";
import { useGetInstrumentsQuery } from "@/features/instruments/instrumentsApiSlice";
import Box from "@mui/material/Box";
import ErrorCard from "@/components/ErrorCard";
import Alert from "@mui/material/Alert";
import { useGetEnvironmentQuery } from "@/features/environment/environmentApiSlice";
import Typography from "@mui/material/Typography";
import { themeColors } from "@/app/theme";
import { capitalize } from "lodash";

export interface AddOrderBtnProps {
  isFirstOrder: boolean;
}

export default function AddOrderBtn(props: AddOrderBtnProps) {
  const [open, setOpen] = useState(false);
  // const [direction, setDirection] = useState<Direction>(Direction.BUY);
  const [direction, setDirection] = useState<Direction | "">("");
  const [underlying, setUnderlying] = useState("");
  const [minBlockSize, setMinBlockSize] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [isQuantityEmpty, setIsQuantityEmpty] = useState(false);

  // Quantity field defaults to 0, its error status depends on value being below min block size and having been edited.
  const isDefaultQuantity = useRef(true);

  const [
    createOrder,
    {
      error: submitError,
      isLoading: isLoadingSubmit,
      isSuccess: isSuccessSubmit, // TODO: visual feedback that POST call was successful?
      isError: isErrorSubmit,
    },
  ] = useCreateParentOrderMutation();

  const {
    data: instrumentsData,
    error: instrumentsError,
    isError: isErrorInstruments,
    isLoading: isLoadingInstruments,
    isSuccess: isSuccessInstruments,
    refetch,
  } = useGetInstrumentsQuery();

  const { data: envData, error: envError, isError: isErrorEnv } = useGetEnvironmentQuery();

  const termDates = `${envData?.["rolling-term-start"] ?? ""}${envData?.["rolling-term-end"] ?? ""}`;

  const handleChangeDirection = (event: SelectChangeEvent) => {
    setDirection(event.target.value as Direction);
  };

  const handleChangeUnderlying = (event: SelectChangeEvent) => {
    const instrument = instrumentsData?.find((i) => i["underlying-name"] === event.target.value) || null;
    if (!instrument) return;
    setUnderlying(instrument["underlying-name"]);
    setMinBlockSize(instrument["min-quantity"]);
  };

  const handleClickOpen = () => {
    // Reset instrument value to empty so that the menu displays 'Select' by default instead of defaulting to the
    // option that was selected when the dialog was last opened.
    setUnderlying("");
    // Reset direction
    setDirection("");
    // Similarly resets order quantity to default
    setQuantity(0);
    isDefaultQuantity.current = true;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    const payload: Partial<ParentOrder> = {
      direction,
      underlying,
      quantity,
      term: envData ? termDates : undefined,
    };
    try {
      const response = await createOrder(payload).unwrap();
      handleClose();
    } catch (error) {
      console.log("[ AddOrderBtn ] handleSubmit ERROR: ", error);
    }
  };

  const InstrumentsInput = () => {
    if (isErrorInstruments) {
      return <ErrorCard error={instrumentsError} refreshFn={refetch} />;
    }

    if (isLoadingInstruments) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 1,
            minWidth: 256,
          }}
        >
          <CircularProgress />
        </Box>
      );
    }

    if (isSuccessInstruments) {
      return (
        <FormControl sx={{ width: 250 }}>
          <InputLabel
            id="select-underlying"
            shrink
            sx={{
              "&.Mui-focused": { color: themeColors.active.primary },
            }}
          >
            Instrument
          </InputLabel>
          <Select
            labelId="select-underlying"
            id="underlying"
            value={underlying}
            label="Instrument"
            onChange={handleChangeUnderlying}
            size={"small"}
            displayEmpty
            renderValue={(value) =>
              value === "" ? (
                <Typography color={themeColors.text.disabled}>Please select</Typography>
              ) : (
                `${capitalize(value)} ${termDates}`
              )
            }
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: themeColors.active.primary,
              },
              ".MuiSelect-icon": {
                color: underlying === "" ? themeColors.text.disabled : null,
              },
            }}
          >
            {instrumentsData.map((instrument, i) => (
              <MenuItem key={i} value={instrument["underlying-name"]}>
                {capitalize(instrument["underlying-name"])} {termDates}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    return null;
  };

  const ErrorMessage = () => {
    const error = submitError || envError;
    if ((isErrorSubmit || isErrorEnv) && error && "data" in error) {
      return (
        <Alert severity="error" sx={{ flex: 1 }}>
          {`[${error.status}]: ${JSON.stringify(error.data)}`}
        </Alert>
      );
    }
    return null;
  };

  const isErrorQuantity = !isDefaultQuantity.current && quantity < minBlockSize;

  return (
    <>
      {props.isFirstOrder ? (
        <Button
          variant="contained"
          color="dark"
          onClick={handleClickOpen}
          sx={{ mt: 2, borderRadius: 2, fontSize: 18 }}
          startIcon={<AddCircleOutlineOutlinedIcon sx={{ height: 22, width: 22 }} />}
          data-testid="add-parent-order"
        >
          Add order
        </Button>
      ) : (
        <Tooltip
          title="Add order manually"
          placement="right"
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: themeColors.white.secondary,
                color: themeColors.black.primary,
                fontSize: 10,
                fontWeight: 600,
                boxShadow: 2,
              },
            },
          }}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -8],
                  },
                },
              ],
            },
          }}
        >
          <IconButton aria-hidden="false" onClick={handleClickOpen} sx={{ ml: 3 }}>
            <AddCircleOutlineOutlinedIcon sx={{ height: 34, width: 34 }} />
          </IconButton>
        </Tooltip>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { maxWidth: "none" },
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            handleSubmit();
          },
        }}
      >
        <DialogTitle sx={{ pb: 0 }}>
          <Typography fontSize={24} color={themeColors.black.secondary}>
            Create order
          </Typography>
          <Typography fontSize={12} color={themeColors.black.secondary}>
            Please complete the fields below.
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 16,
            top: 12,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ pt: 0 }}>
          <Stack direction="row" spacing={2} mt={2}>
            <FormControl sx={{ width: 152 }}>
              <InputLabel
                id="select-direction"
                shrink
                sx={{
                  "&.Mui-focused": { color: themeColors.active.primary },
                }}
              >
                Direction
              </InputLabel>
              <Select
                labelId="select-direction"
                id="direction"
                value={direction}
                label="Direction"
                onChange={handleChangeDirection}
                size={"small"}
                displayEmpty
                renderValue={(value) =>
                  (value as string) === "" ? (
                    <Typography color={themeColors.text.disabled}>Please select</Typography>
                  ) : (
                    capitalize(value)
                  )
                }
                sx={{
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: themeColors.active.primary,
                  },
                  ".MuiSelect-icon": {
                    color: direction === "" ? themeColors.text.disabled : null,
                  },
                }}
              >
                <MenuItem value={Direction.BUY}>Buy</MenuItem>
                <MenuItem value={Direction.SELL}>Sell</MenuItem>
              </Select>
            </FormControl>
            <InstrumentsInput />
            <NumericFormat
              value={quantity}
              onValueChange={(values, sourceInfo) => {
                if (values.floatValue !== null && values.floatValue !== undefined) {
                  setQuantity(values.floatValue);
                  setIsQuantityEmpty(false);
                  isDefaultQuantity.current = false;
                } else {
                  setIsQuantityEmpty(true);
                }
              }}
              thousandSeparator
              customInput={TextField}
              size={"small"}
              label="Quantity"
              id="quantity"
              helperText={isErrorQuantity && `The minimum block size is ${minBlockSize}.`}
              // Textfield has error status only when it is below block size due to a value being entered, the field
              // defaults to having a value of 0 which should not cause error status.
              error={isErrorQuantity}
              sx={{
                "width": 164,
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: isErrorQuantity ? themeColors.error.primary : themeColors.active.primary,
                  },
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: isErrorQuantity ? themeColors.error.primary : themeColors.active.primary,
                },
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <ErrorMessage />
          <LoadingButton
            loading={isLoadingSubmit}
            disabled={!underlying || quantity < minBlockSize || isQuantityEmpty || !direction}
            type="submit"
            variant="contained"
            data-testid="submit-parent-order"
            sx={{ mr: 2, mb: 3, mt: 1, borderRadius: 2, fontSize: 18 }}
          >
            {/* Span protects against a bug involving google translate */}
            <span>Done</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

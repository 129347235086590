import { useAppDispatch, useAppSelector } from "@/store/hooks";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { numericFormatter } from "react-number-format";
import { ParentOrderResponse, useGetParentOrderQuery } from "../parentApiSlice";
import ErrorCard from "@/components/ErrorCard";
import { capitalize } from "lodash";
import FlagIcon from "./FlagIcon";
import MoreDetails from "./MoreDetails";
import { selectParent, setParentOrder } from "../parentSlice";
import { useEffect, useState } from "react";
import { themeColors, themeVariants } from "@/app/theme";
import { setInstrument } from "@/features/instruments/instrumentSlice";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Tooltip from "@mui/material/Tooltip";
import CancelParentOrder from "./CancelParentOrder";
import SendRfqDialog from "./SendRfqDialog";
import { setElectronicPrices, setPrice } from "@/features/prices/pricesSlice";
import Skeleton from "@mui/material/Skeleton";
import StatusBarGroup from "./StatusBarGroup";
import { refetchTimeouts } from "@/utils/Constants";
import { matureStates, OrderState } from "../../child/childApiSlice";
import { setActiveRfq } from "@/features/notifications/notificationSlice";

export interface ParentOrderCardProps {
  order: ParentOrderResponse;
}

const FLAG_HEIGHT = 24;

export default function ParentOrderCard(props: ParentOrderCardProps) {
  const dispatch = useAppDispatch();
  const selectedParent = useAppSelector(selectParent);

  const [isActive, setIsActive] = useState(selectedParent?.["parent-id"] === props.order["parent-id"]);

  useEffect(() => {
    setIsActive(selectedParent?.["parent-id"] === props.order["parent-id"]);
  }, [selectedParent]);

  const { currentData, error, isError, isLoading, isSuccess, refetch } = useGetParentOrderQuery(
    props.order["parent-id"],
    {
      // Refetch parent data every minute to check for aggregation updates.
      // This is syncrhonised with the child update schedule.
      pollingInterval: refetchTimeouts.fast,
    }
  );

  const [isCancel, setIsCancel] = useState(false);
  const [isCancelable, setIsCancelable] = useState(false);

  useEffect(() => {
    if (currentData && currentData["parent-order-aggregation"]) {
      // If the status of this parent's child orders is any of that layed out in
      // the matureStates variable, then the parent is not cancellable.
      const childStates = Object.keys(
        currentData["parent-order-aggregation"]["child-orders-quantity-by-state"]
      ) as OrderState[];
      setIsCancelable(childStates.every((childState) => !matureStates.includes(childState)));
    }
    if (isActive && currentData) {
      // Make sure the store data for the selected parent is up-to-date.
      dispatch(setParentOrder(currentData));
    }
  }, [currentData]);

  // RFQ modal starts off closed
  const [rfqDialogOpen, setRfqDialogOpen] = useState(false);

  const closeTooltip = isCancelable && !isActive ? "Cancel order" : "Close";

  const handleClickPaper = () => {
    if (isCancel || !currentData) return;
    dispatch(setParentOrder(isActive ? null : currentData));
    if (!isActive) {
      // On select, set the underlying to == this parent's underlying.
      dispatch(setInstrument(currentData.underlying));
      // and force the RFQ response state to be falsy.
      dispatch(setActiveRfq(null));
    } else {
      // On deselct, reset the selected price.
      dispatch(setPrice(null));
      // When a parent card is deselected, the instrument selection dropdown must default back to its null value.
      dispatch(setInstrument(""));
      // Force the electronic prices to reset.
      dispatch(
        setElectronicPrices({
          floor: 0,
          ceiling: 0,
        })
      );
    }
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Causes button click to select its parent card but not deselect it if already selected.
    if (isActive) {
      event.stopPropagation();
    }
    setRfqDialogOpen(true);
  };

  const handleCloseRfqDialog = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setRfqDialogOpen(false);
  };

  const handleCancelOrder = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (isActive) {
      // Deselect the card to trigger the collapse animation
      handleClickPaper();
    } else {
      setIsCancel(true);
    }
  };

  if (isError) {
    return <ErrorCard error={error} refreshFn={refetch} />;
  }

  if (isLoading) {
    return <Skeleton height={96} width="100%" variant="rounded" />;
  }

  if (isSuccess && currentData) {
    return (
      <Paper
        data-testid={`parent-order-${props.order["parent-id"]}`}
        elevation={1}
        sx={{
          "borderRadius": 4,
          "border": !isActive ? `0.3px solid ${themeColors.border}` : null,
          "py": 1,
          "pl": 3,
          "pr": 2,
          "mb": 2,
          "mx": 1,
          "backgroundColor": isCancel
            ? themeColors.white.secondary
            : isActive
            ? themeVariants.selected.backgroundColor
            : themeVariants.unselected.backgroundColor,
          "color": isActive ? themeVariants.selected.color : themeVariants.unselected.color,
          "transitionDuration": 400,
          "transitionTimingFunction": "ease",
          "transitionProperty": "all",
          "cursor": "pointer",
          "&:hover": {
            backgroundColor: !isActive && !isCancel ? themeColors.white.secondary : null,
          },
        }}
        onClick={() => handleClickPaper()}
      >
        {isCancel ? (
          <CancelParentOrder handleClose={() => setIsCancel(false)} id={props.order["parent-id"]} />
        ) : (
          <Collapse in={isActive} collapsedSize={100}>
            <Stack sx={{ height: 150 }}>
              <Stack direction="row" sx={{ alignItems: "center" }}>
                <FlagIcon height={FLAG_HEIGHT} underlying={currentData.underlying} />
                <Typography variant="h5" fontWeight={600} sx={{ flex: 1, ml: 1 }}>
                  {capitalize(currentData.underlying)}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {isActive && currentData?.["rfq-client-name"] === undefined && (
                    <>
                      <Button
                        startIcon={<img src="./send-rfq.svg" />}
                        variant="outlined"
                        size="small"
                        onClick={handleButtonClick}
                        color={isActive ? "rfqLight" : "rfqDark"}
                        sx={{ fontSize: 12 }}
                      >
                        Send RFQ
                      </Button>
                      <SendRfqDialog parent={currentData} open={rfqDialogOpen} onClose={handleCloseRfqDialog} />
                    </>
                  )}
                  {!!currentData?.["rfq-client-name"] && (
                    <Typography variant="caption">{`Client: ${currentData["rfq-client-name"]}`}</Typography>
                  )}
                  {(isCancelable || isActive) && (
                    <Tooltip title={closeTooltip} placement="top">
                      <IconButton onClick={handleCancelOrder} sx={{ height: 28, width: 28 }}>
                        <ClearIcon
                          sx={{
                            height: 20,
                            width: 20,
                            color: isActive ? themeVariants.selected.color : themeVariants.unselected.color,
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              </Stack>

              <Typography variant="caption" ml={`${FLAG_HEIGHT * 1.5 + 8}px`}>
                {currentData.term}
              </Typography>

              <Grid container>
                <Grid item xs={8}>
                  <Stack height="100%" justifyContent="space-between">
                    <Stack direction="row" mr={2}>
                      <Stack>
                        <Typography variant="caption">Side</Typography>
                        <Typography variant="body1" fontSize={20} fontWeight={500}>
                          {capitalize(currentData.direction)}
                        </Typography>
                      </Stack>
                      <Stack mx="auto">
                        <Typography variant="caption">Quantity</Typography>
                        <Typography variant="numeric_body1" fontSize={20} fontWeight={500}>
                          {numericFormatter(String(currentData.quantity), {
                            thousandSeparator: ",",
                          })}
                        </Typography>
                      </Stack>
                    </Stack>
                    {isActive && currentData["parent-order-aggregation"]?.["child-orders-quantity-by-state"] && (
                      <StatusBarGroup aggregation={currentData["parent-order-aggregation"]} />
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  {isActive && <MoreDetails parent={currentData} />}
                </Grid>
              </Grid>
            </Stack>
          </Collapse>
        )}
      </Paper>
    );
  }

  return null;
}

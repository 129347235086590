import { OfferType } from "@/features/offers/offersApiSlice";
import { OrderState } from "@/features/orders/child/childApiSlice";
import { createTheme } from "@mui/material/styles";

/**
 * InTick brand colours. US spelling is just a code convention.
 * Constant defined because it's less of a mouthful than typing out
 * `theme.palette.primary.main` all the time.
 */
export const themeColors = {
  logo: {
    primary: "#2C386C",
    secondary: "#556DAA",
    tertiary: "#7369FD",
  },
  text: {
    primary: "#282828",
    secondary: "#5A5A5A",
    disabled: "#999999",
  },
  white: {
    primary: "#FFFFFF",
    secondary: "#F9F8F5",
    tertiary: "#FCFCFC",
    quaternary: "#F9F9F9",
    disabled: "#F3F3F3",
  },
  black: {
    primary: "#393939",
    secondary: "#5A5A5A",
    tertiary: "#D8D8D8",
  },
  buttons: {
    chatWithUs: "#D9E9B7",
    guidance: "#1479F5",
    pricingChart: "#F24B0F",
  },
  border: "#C0C0C0",
  prices: {
    [OfferType.ASK]: "#E05E57",
    [OfferType.BID]: "#3D419C",
  },
  state: {
    [OrderState.Working]: "#AAE2EE",
    [OrderState.AwaitingMatch]: "#F1CCB7",
    [OrderState.Matched]: "#FFF2D0",
    [OrderState.PartialBrokerAccepted]: "#FFF2D0",
    [OrderState.BrokerAccepted]: "#D9E9B7",
    [OrderState.PartialBrokerFilled]: "#D9E9B7",
    [OrderState.BrokerFilled]: "#05812E",
    [OrderState.Error]: "#940E07",
    [OrderState.Cancelled]: "#F3EAEA",
    [OrderState.Expired]: "#F3EAEA",
    [OrderState.Rejected]: "#940E07",
  },
  success: {
    primary: "#05812E",
    secondary: "#D9E9B7",
  },
  active: {
    primary: "#0B79D0",
    secondary: "#F4F7F9",
  },
  error: {
    primary: "#940E07",
    secondary: "#F3EAEA",
  },
  popup: {
    backgroundColor: "#F5F5F5",
  },
};

/**
 * Enables consistent setting of colours for selected/
 * unselected variants of components.
 */
export const themeVariants = {
  selected: {
    backgroundColor: themeColors.black.primary,
    color: themeColors.white.primary,
  },
  unselected: {
    backgroundColor: themeColors.white.primary,
    color: themeColors.text.primary,
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: themeColors.logo.primary,
    },
    secondary: {
      main: themeColors.logo.secondary,
    },
    background: {
      default: themeColors.white.tertiary,
    },
    warning: {
      main: themeColors.error.secondary,
    },
    success: {
      main: themeColors.success.primary,
    },
    error: {
      main: themeColors.error.primary,
    },
    info: {
      main: themeColors.active.primary,
    },
    text: {
      primary: themeColors.text.primary,
      secondary: themeColors.text.secondary,
      disabled: themeColors.text.disabled,
    },
  },
  typography: {
    fontFamily: `"Inter", "Poppins", "Roboto", "Helvetica", "Arial", sans-serif`,
    numeric_h4: {
      fontFamily: `"Poppins", "Inter", "Roboto", "Helvetica", "Arial", sans-serif`,
      fontWeight: 400,
      fontSize: "2.125rem",
      lineHeight: 1.235,
      letterSpacing: "0.00735em",
    },
    numeric_h5: {
      fontFamily: `"Poppins", "Inter", "Roboto", "Helvetica", "Arial", sans-serif`,
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: 1.334,
      letterSpacing: "0em",
    },
    numeric_body1: {
      fontFamily: `"Poppins", "Inter", "Roboto", "Helvetica", "Arial", sans-serif`,
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

const augmentedTheme = createTheme(theme, {
  palette: {
    chatWithUs: theme.palette.augmentColor({
      color: {
        main: themeColors.buttons.chatWithUs,
      },
      name: "chatWithUs",
    }),
    guidance: theme.palette.augmentColor({
      color: {
        main: themeColors.buttons.guidance,
      },
      name: "guidance",
    }),
    pricingChart: theme.palette.augmentColor({
      color: {
        main: themeColors.buttons.pricingChart,
      },
      name: "pricingChart",
    }),
    rfqLight: theme.palette.augmentColor({
      color: {
        main: themeColors.black.tertiary,
      },
      name: "rfqLight",
    }),
    rfqDark: theme.palette.augmentColor({
      color: {
        main: themeColors.text.primary,
      },
      name: "rfqDark",
    }),
    [OfferType.ASK]: theme.palette.augmentColor({
      color: {
        main: themeColors.prices[OfferType.ASK],
        contrastText: themeColors.white.primary,
      },
      name: OfferType.ASK,
    }),
    [OfferType.BID]: theme.palette.augmentColor({
      color: {
        main: themeColors.prices[OfferType.BID],
      },
      name: OfferType.BID,
    }),
    dark: theme.palette.augmentColor({
      color: {
        main: themeColors.black.primary,
      },
      name: "dark",
    }),
  },
});

export default augmentedTheme;

// -----------------------Pallete type overrides-------------------------------
declare module "@mui/material/styles" {
  interface Palette {
    chatWithUs: Palette["primary"];
    guidance: Palette["primary"];
    pricingChart: Palette["primary"];
    [OfferType.ASK]: Palette["primary"];
    [OfferType.BID]: Palette["primary"];
    rfqLight: Palette["primary"];
    rfqDark: Palette["primary"];
    dark: Palette["primary"];
  }

  interface PaletteOptions {
    chatWithUs?: PaletteOptions["primary"];
    guidance?: PaletteOptions["primary"];
    pricingChart?: PaletteOptions["primary"];
    [OfferType.ASK]?: PaletteOptions["primary"];
    [OfferType.BID]?: PaletteOptions["primary"];
    rfqLight?: PaletteOptions["primary"];
    rfqDark?: PaletteOptions["primary"];
    dark?: PaletteOptions["primary"];
  }
}

// ---------------------Typography type overrides-----------------------------
declare module "@mui/material/styles" {
  interface TypographyVariants {
    numeric_h4: React.CSSProperties;
    numeric_h5: React.CSSProperties;
    numeric_body1: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    numeric_h4?: React.CSSProperties;
    numeric_h5?: React.CSSProperties;
    numeric_body1?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    numeric_h4: true;
    numeric_h5: true;
    numeric_body1: true;
  }
}

// -----------------------Button type overrides-------------------------------
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    chatWithUs: true;
    guidance: true;
    pricingChart: true;
    rfqLight: true;
    rfqDark: true;
    [OfferType.ASK]: true;
    [OfferType.BID]: true;
    dark: true;
  }
}

// ------------------------Chip type overrides--------------------------------
declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    [OfferType.ASK]: true;
    [OfferType.BID]: true;
  }
}

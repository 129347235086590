import { themeColors } from "@/app/theme";
import Typography from "@mui/material/Typography";

interface ErrorAlertProps {
  message: string;
}

const ErrorAlert = ({ message }: ErrorAlertProps) => (
  <Typography
    sx={{
      fontSize: 10,
      color: themeColors.error.primary,
      position: "absolute",
      bottom: 4,
      right: 24,
    }}
  >
    {message}
  </Typography>
);

export default ErrorAlert;

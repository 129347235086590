import { baseApi } from "@/config/BaseQuery";
import { OfferType } from "@/features/offers/offersApiSlice";

export enum OrderState {
  Working = "WORKING",
  AwaitingMatch = "AWAITING_MATCH",
  Matched = "MATCHED",
  PartialBrokerAccepted = "PARTIAL_BROKER_ACCEPTED",
  BrokerAccepted = "BROKER_ACCEPTED",
  PartialBrokerFilled = "PARTIAL_BROKER_FILLED",
  BrokerFilled = "BROKER_FILLED",
  Error = "ERROR",
  Cancelled = "CANCELLED",
  Rejected = "REJECTED",
  Expired = "EXPIRED",
}

export const orderStateLabels = {
  [OrderState.Working]: "Working",
  [OrderState.AwaitingMatch]: "Awaiting Match",
  [OrderState.Matched]: "Matched",
  [OrderState.PartialBrokerAccepted]: "Partially Broker Accepted",
  [OrderState.BrokerAccepted]: "Broker Accepted",
  [OrderState.PartialBrokerFilled]: "Partially Broker Filled",
  [OrderState.BrokerFilled]: "Broker Filled",
  [OrderState.Error]: "Error",
  [OrderState.Cancelled]: "Cancelled",
  [OrderState.Rejected]: "Rejected",
  [OrderState.Expired]: "Expired",
};

export const matureStates = [
  OrderState.Matched,
  OrderState.PartialBrokerAccepted,
  OrderState.BrokerAccepted,
  OrderState.PartialBrokerFilled,
  OrderState.BrokerFilled,
];

export interface ChildOrder {
  "child-id": string;
  "parent-id": string;
  "client-name": string;
  "quantity": number;
  "price": number;
  "bid-ask": OfferType;
  "residual": boolean;
  "state": OrderState;
  "match-request-id"?: string;
}

export interface CreateChildOrderPayload {
  "parent-id": string;
  "quantity": number;
  "price": number;
  "residual": boolean;
  "tier": number;
}

export interface CreateChildOrderError {
  input: CreateChildOrderPayload;
  errors: string[];
}

export interface InflightChildOrdersResponse {
  inflight: InflightChildOrder[];
}

export interface InflightChildOrder {
  "id": string;
  "last-updated": string;
}

const childApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getChildOrder: build.query<ChildOrder, string>({
      query: (id: string) => `/orders/child/${id}`,
      providesTags: (result, error, arg) => [{ type: "SelectedChildOrder", id: arg }],
    }),
    getInflightChildOrders: build.query<InflightChildOrdersResponse, string>({
      query: (parentId: string) => `/orders/child/last-updated/${parentId}`,
      providesTags: (result, error, arg) => [{ type: "InflightChildOrders", id: arg }],
    }),
    getChildOrders: build.query<ChildOrder[], InflightChildOrder[]>({
      async queryFn(_arg: any, _queryApi: any, _extraOptions: any, fetchWithBQ: any) {
        if (!_arg.length) {
          return { data: [] };
        }
        const promises = _arg.map((inflightOffer: any) => fetchWithBQ(`/orders/child/${inflightOffer.id}`));
        const result = await Promise.all(promises);
        const data = result.map((response) => response.data as ChildOrder);
        const errors = result.filter((response) => !!response.error);
        return errors.length ? { error: errors[0]?.error } : { data: data };
      },
    } as any),
    createChildOrder: build.mutation<ChildOrder, CreateChildOrderPayload>({
      query: (body) => ({
        url: "/orders/child",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["SelectedChildOrder", "InflightChildOrders", "Offers", "SelectedParentOrder"],
    }),
    deleteChildOrder: build.mutation<void, string>({
      query: (id) => ({
        url: `/orders/child/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SelectedChildOrder", "InflightChildOrders", "Offers", "SelectedParentOrder"],
    }),
  }),
});

export const {
  useGetChildOrderQuery,
  useGetInflightChildOrdersQuery,
  useGetChildOrdersQuery,
  useCreateChildOrderMutation,
  useDeleteChildOrderMutation,
} = childApiSlice;
